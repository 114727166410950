import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const TelegramFailure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    alert("Autenticación con Telegram fallida.");
    navigate("/"); // Redirige al home o a una página de error
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen">
      <p>Autenticación fallida.</p>
    </div>
  );
};

export default TelegramFailure;
