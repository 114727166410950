import * as am5 from "@amcharts/amcharts5";

export const countries_id = [
  "AL",
  "AD",
  "AM",
  "AT",
  "AZ",
  "BY",
  "BE",
  "BA",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "GE",
  "DE",
  "GR",
  "HU",
  "IS",
  "IE",
  "IT",
  "KZ",
  "XK",
  "LV",
  "LI",
  "LT",
  "LU",
  "MT",
  "MD",
  "MC",
  "ME",
  "NL",
  "MK",
  "NO",
  "PL",
  "PT",
  "RO",
  "RU",
  "SM",
  "RS",
  "SK",
  "SI",
  "ES",
  "SE",
  "CH",
  "TR",
  "UA",
  "GB",
  "VA",
  "AF",
  "AO",
  "AI",
  "AG",
  "AR",
  "AU",
  "BS",
  "BH",
  "BD",
  "BB",
  "BZ",
  "BJ",
  "BM",
  "BT",
  "BO",
  "BR",
  "BN",
  "BF",
  "BI",
  "CV",
  "KH",
  "CM",
  "CA",
  "KY",
  "CF",
  "TD",
  "CL",
  "CN",
  "CO",
  "KM",
  "CG",
  "CD",
  "CR",
  "CI",
  "CU",
  "DJ",
  "DM",
  "DO",
  "EC",
  "EG",
  "SV",
  "GQ",
  "ER",
  "SZ",
  "ET",
  "FJ",
  "GA",
  "GM",
  "GH",
  "GD",
  "GT",
  "GN",
  "GW",
  "GY",
  "HT",
  "HN",
  "IN",
  "ID",
  "IR",
  "IQ",
  "IL",
  "JM",
  "JP",
  "JO",
  "KE",
  "KI",
  "KP",
  "KR",
  "KW",
  "KG",
  "LA",
  "LB",
  "LS",
  "LR",
  "LY",
  "MG",
  "MW",
  "MY",
  "MV",
  "ML",
  "MH",
  "MR",
  "MU",
  "MX",
  "FM",
  "MN",
  "MA",
  "MZ",
  "MM",
  "NA",
  "NR",
  "NP",
  "NZ",
  "NI",
  "NE",
  "NG",
  "OM",
  "PK",
  "PW",
  "PA",
  "PG",
  "PY",
  "PE",
  "PH",
  "QA",
  "RW",
  "KN",
  "LC",
  "VC",
  "WS",
  "ST",
  "SA",
  "SN",
  "SC",
  "SL",
  "SG",
  "SB",
  "SO",
  "ZA",
  "SS",
  "LK",
  "SD",
  "SR",
  "SY",
  "TW",
  "TJ",
  "TZ",
  "TH",
  "TL",
  "TG",
  "TO",
  "TT",
  "TN",
  "TM",
  "TV",
  "UG",
  "AE",
  "US",
  "UY",
  "UZ",
  "VU",
  "VE",
  "VN",
  "YE",
  "ZM",
  "ZW", // Países no europeos
];

export const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "0.5rem",
    backgroundImage:
      "linear-gradient(90deg, #1D1C1F 0%, #1C1C1E 25%, #272432 50%, #322B43 75%)",
    padding: "0.5rem", // p-2
    outline: "none",
    border: "none",
    boxShadow: "none",
    transition: "box-shadow 0.2s ease-in-out",
    color: "#fff",
    width: "220px",
    textAlign: "center",
    fontSize: "1.16rem",
    backgroundColor: "#1a202c", // Color de fondo del control
    borderColor: "#4a5568", // Color del borde
    "&:hover": {
      borderColor: "#718096", // Color del borde al pasar el mouse
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 20,
    borderRadius: "0.5rem",
  }),
  menuList: (provided) => ({
    ...provided,
    "::-webkit-scrollbar": {
      "-webkit-appearance": "none",
      width: "10px",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#797979",
      borderRadius: "20px",
      border: "2px solid #f1f2f3",
    },
    "::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4a5568" : provided.backgroundColor,
    color: state.isSelected ? "#ffffff" : provided.color,
    "&:hover": {
      backgroundColor: "#f8f9fa", // Color muy claro, casi blanco
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#fff", // Color blanco para el placeholder
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#e5e7eb", // bg-gray-200
    borderRadius: "0.5rem", // rounded-lg
    padding: "0.2rem", // some padding for the tag
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#333", // text color
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#6b7280", // text-gray-500
    ":hover": {
      backgroundColor: "#9ca3af", // hover:bg-gray-400
      color: "#fff", // white text on hover
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#ffffff", // Color del texto para el valor seleccionado en el input
  }),
};

export const countries2 = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Korea",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
  "Remote",
];

export const countryNameToCodeMap = {
  afghanistan: "AF",
  albania: "AL",
  algeria: "DZ",
  andorra: "AD",
  angola: "AO",
  "antigua and barbuda": "AG",
  argentina: "AR",
  armenia: "AM",
  australia: "AU",
  austria: "AT",
  azerbaijan: "AZ",
  bahamas: "BS",
  bahrain: "BH",
  bangladesh: "BD",
  barbados: "BB",
  belarus: "BY",
  belgium: "BE",
  belize: "BZ",
  benin: "BJ",
  bhutan: "BT",
  bolivia: "BO",
  "bosnia and herzegovina": "BA",
  botswana: "BW",
  brazil: "BR",
  brunei: "BN",
  bulgaria: "BG",
  "burkina faso": "BF",
  burundi: "BI",
  "cabo verde": "CV",
  cambodia: "KH",
  cameroon: "CM",
  canada: "CA",
  "central african republic": "CF",
  chad: "TD",
  chile: "CL",
  china: "CN",
  colombia: "CO",
  comoros: "KM",
  congo: "CG",
  "congo, democratic republic of the": "CD",
  "costa rica": "CR",
  croatia: "HR",
  cuba: "CU",
  cyprus: "CY",
  czechia: "CZ",
  denmark: "DK",
  djibouti: "DJ",
  dominica: "DM",
  "dominican republic": "DO",
  "east timor": "TL",
  ecuador: "EC",
  egypt: "EG",
  "el salvador": "SV",
  "equatorial guinea": "GQ",
  eritrea: "ER",
  estonia: "EE",
  eswatini: "SZ",
  ethiopia: "ET",
  fiji: "FJ",
  finland: "FI",
  france: "FR",
  gabon: "GA",
  gambia: "GM",
  georgia: "GE",
  germany: "DE",
  ghana: "GH",
  greece: "GR",
  grenada: "GD",
  guatemala: "GT",
  guinea: "GN",
  "guinea-bissau": "GW",
  guyana: "GY",
  haiti: "HT",
  honduras: "HN",
  "hong kong": "HK",
  hungary: "HU",
  iceland: "IS",
  india: "IN",
  indonesia: "ID",
  iran: "IR",
  iraq: "IQ",
  ireland: "IE",
  israel: "IL",
  italy: "IT",
  "ivory coast": "CI",
  jamaica: "JM",
  japan: "JP",
  jordan: "JO",
  kazakhstan: "KZ",
  kenya: "KE",
  kiribati: "KI",
  "korea, north": "KP",
  "korea, south": "KR",
  "Soth Korea": "KR",
  kosovo: "XK",
  kuwait: "KW",
  kyrgyzstan: "KG",
  laos: "LA",
  latvia: "LV",
  lebanon: "LB",
  lesotho: "LS",
  liberia: "LR",
  libya: "LY",
  liechtenstein: "LI",
  lithuania: "LT",
  luxembourg: "LU",
  madagascar: "MG",
  malawi: "MW",
  malaysia: "MY",
  maldives: "MV",
  mali: "ML",
  malta: "MT",
  "marshall islands": "MH",
  mauritania: "MR",
  mauritius: "MU",
  mexico: "MX",
  micronesia: "FM",
  moldova: "MD",
  monaco: "MC",
  mongolia: "MN",
  montenegro: "ME",
  morocco: "MA",
  mozambique: "MZ",
  myanmar: "MM",
  namibia: "NA",
  nauru: "NR",
  nepal: "NP",
  netherlands: "NL",
  "new zealand": "NZ",
  nicaragua: "NI",
  niger: "NE",
  nigeria: "NG",
  "north macedonia": "MK",
  norway: "NO",
  oman: "OM",
  pakistan: "PK",
  palau: "PW",
  palestine: "PS",
  panama: "PA",
  "papua new guinea": "PG",
  paraguay: "PY",
  peru: "PE",
  philippines: "PH",
  poland: "PL",
  portugal: "PT",
  qatar: "QA",
  romania: "RO",
  russia: "RU",
  rwanda: "RW",
  "saint kitts and nevis": "KN",
  "saint lucia": "LC",
  "saint vincent and the grenadines": "VC",
  samoa: "WS",
  "san marino": "SM",
  "sao tome and principe": "ST",
  "saudi arabia": "SA",
  senegal: "SN",
  serbia: "RS",
  seychelles: "SC",
  "sierra leone": "SL",
  singapore: "SG",
  slovakia: "SK",
  slovenia: "SI",
  "solomon islands": "SB",
  somalia: "SO",
  "south africa": "ZA",
  "south sudan": "SS",
  "south korea": "KR",
  spain: "ES",
  "sri lanka": "LK",
  sudan: "SD",
  suriname: "SR",
  sweden: "SE",
  switzerland: "CH",
  syria: "SY",
  taiwan: "TW",
  tajikistan: "TJ",
  tanzania: "TZ",
  thailand: "TH",
  togo: "TG",
  tonga: "TO",
  "trinidad and tobago": "TT",
  tunisia: "TN",
  turkey: "TR",
  turkmenistan: "TM",
  tuvalu: "TV",
  uganda: "UG",
  ukraine: "UA",
  "united arab emirates": "AE",
  "united kingdom": "GB",
  "united states": "US",
  usa: "US",
  uruguay: "UY",
  uzbekistan: "UZ",
  vanuatu: "VU",
  "vatican city": "VA",
  venezuela: "VE",
  vietnam: "VN",
  yemen: "YE",
  zambia: "ZM",
  zimbabwe: "ZW",
};

// funciones.js

/**
 * Crea un bullet para la serie del gráfico.
 * @param {object} root - El objeto raíz de amCharts.
 * @returns {object} - El objeto bullet creado.
 */
export const createBullet = (root) => {
  return am5.Bullet.new(root, {
    locationX: 1,
    sprite: am5.Label.new(root, {
      text: "{originalCount}",
      fill: am5.color(0xffffff),
      centerY: am5.p50,
      centerX: am5.p0,
      dx: 4,
      populateText: true,
    }),
  });
};
/**
 * Capitaliza la primera letra de una cadena.
 * @param {string} str - La cadena a capitalizar.
 * @returns {string} - La cadena capitalizada.
 */
export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

const customCapitalizations = {
  aws: "AWS",
  gcp: "GCP",
  ai: "AI",
  ml: "ML",
  nlp: "NLP",
  sql: "SQL",
  api: "API",
  gpu: "GPU",
  rpa: "RPA",
  saas: "SaaS",
  paas: "PaaS",
  iaas: "IaaS",
  bi: "BI",
  ci: "CI",
  cd: "CD",
  ocr: "OCR",
  tts: "TTS",
  sdn: "SDN",
  iot: "IoT",
  javascript: "JavaScript",
  typescript: "TypeScript",
  openai: "OpenAI",
  postgresql: "PostgreSQL",
  pytorch: "PyTorch",
  tensorflow: "TensorFlow",
  tensorflowjs: "TensorFlow.js",
  csharp: "C#",
};

export const capitalizeWords = (str) => {
  // Divide la cadena en palabras
  return str
    .split(" ") // Divide la cadena en palabras
    .map((word) => {
      // Si la palabra está en el diccionario, usa la capitalización personalizada
      if (customCapitalizations[word.toLowerCase()]) {
        return customCapitalizations[word.toLowerCase()];
      }
      // Capitaliza la primera letra de cada palabra
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" "); // Une las palabras con espacios
};
/**
 * Ordena los ejes de categorías del gráfico.
 * @param {object} series - La serie de datos del gráfico.
 * @param {object} yAxis - El eje Y del gráfico.
 * @param {object} yRenderer - El renderizador del eje Y.
 */
export const sortCategoryAxis = (series, yAxis, yRenderer) => {
  series.dataItems.sort((x, y) => {
    return y.get("graphics").y() - x.get("graphics").y();
  });

  let easing = am5.ease.out(am5.ease.cubic);

  am5.array.each(yAxis.dataItems, (dataItem) => {
    let seriesDataItem = series.dataItems.find(
      (item) => item.get("categoryY") === dataItem.get("category")
    );

    if (seriesDataItem) {
      let index = series.dataItems.indexOf(seriesDataItem);
      let column = seriesDataItem.get("graphics");

      let fy =
        yRenderer.positionToCoordinate(yAxis.indexToPosition(index)) -
        column.height() / 2;

      if (index !== dataItem.get("index")) {
        dataItem.set("index", index);

        let x = column.x();
        let y = column.y();

        column.set("dy", -(fy - y));
        column.set("dx", x);

        column.animate({ key: "dy", to: 0, duration: 600, easing: easing });
        column.animate({ key: "dx", to: 0, duration: 600, easing: easing });
      } else {
        column.animate({ key: "y", to: fy, duration: 600, easing: easing });
        column.animate({ key: "x", to: 0, duration: 600, easing: easing });
      }
    }
  });

  yAxis.dataItems.sort((x, y) => {
    return x.get("index") - y.get("index");
  });
};

/**
 * Procesa los datos filtrados para el gráfico.
 * @param {object} filteredTechStack - Los datos filtrados de la tecnología.
 * @returns {array} - Los datos procesados.
 */
export const getProcessedData = (filteredTechStack) =>
  Object.entries(filteredTechStack)
    .map(([technology, count]) => ({
      technology: capitalize(technology),
      originalCount: count,
      logCount: count > 1 ? Math.log(count) : count === 1 ? 0.2 : 0,
    }))
    .sort((a, b) => a.logCount - b.logCount);

export const setColors1 = [
  am5.color(0x123456),
  am5.color(0x67b7dc),
  am5.color(0x8b0000),
  am5.color(0xdda0dd),
  am5.color(0x483d8b),
  am5.color(0xc6e2ff),
  am5.color(0xb0e0e6),
  am5.color(0x123456),
  am5.color(0xdda0dd),
  am5.color(0xc71585),
];

export const setColors2 = [
  { color: am5.color(0x123456) },
  { color: am5.color(0x67b7dc) },
  { color: am5.color(0x8b0000) },
];

export const columnStyles = {
  draggable: true,
  cursorOverStyle: "pointer",
  cornerRadiusBR: 10,
  cornerRadiusTR: 10,
  strokeOpacity: 0,
  height: 20,
  fillOpacity: 0.5,
  padding: 10,
  innerPadding: 10,
};

export const seriesProps = {
  name: "Tech Stack",
  valueXField: "logCount",
  categoryYField: "technology",
};

export const chartProps = {
  panX: false,
  panY: false,
  wheelX: "none",
  wheelY: "none",
  paddingLeft: 30,
};

export const ruleProps = {
  fontSize: 14,
  fontFamily: "Arial",
  textAlign: "center",
};

export const floatingModalContent = {
  transition: "top 0.3s ease", // Transición suave al cambiar la posición
  left: "auto", // Evitar posicionamiento desde la izquierda
  right: "10px", // Coloca el modal a 10px desde el lado derecho
  bottom: "auto", // Evitar posicionamiento desde abajo
  // position: "fixed", // Fija el modal en la pantalla
  pointerEvents: "auto",
  background: "transparent", // Hace que el fondo del modal sea transparente
  maxHeight: "90vh", // Limita la altura máxima del modal
  overflowY: "auto", // Permite el desplazamiento vertical si es necesario
  WebkitOverflowScrolling: "touch", // Mejora la experiencia de desplazamiento en dispositivos táctiles
  borderRadius: "20px", // Bordes redondeados del modal
  outline: "none", // Elimina el borde por defecto del modal
  padding: "0", // Elimina el padding por defecto
  zIndex: 9999, // Asegura que el modal esté encima de otros elementos
  border: "none", // Elimina el borde por defecto del modal
};

export const FloatingModalSecondDiv = {
  background: "#AED",
  opacity: 0.3,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 0,
  borderRadius: "4px",
};

export const FloatingModalthirdDiv = {
  position: "relative",
  zIndex: 1,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // background: "linear-gradient(to bottom right, #32CD32, #0B3D91)", // Gradiente de verde a azul oscuroq
};

export const categories2 = [
  "Technical/Engineer",
  "Manager/Leader",
  "Analysis/Consulting",
  "Marketing",
  "Other",
];

export const calculateChartHeight = (itemCount) => {
  if (itemCount >= 20) {
    return "70vh";
  } else if (itemCount >= 10) {
    return "60vh";
  } else if (itemCount >= 5) {
    return "50vh";
  } else {
    return "25vh";
  }
};

// for MapChart

export const normalizeCountryName = (name) => {
  if (
    !name ||
    name.trim().toLowerCase() === "unknown" ||
    name.trim().toLowerCase() === "n/a"
  ) {
    return "United States";
  }

  const cleanedName = name.trim().toLowerCase();

  if (
    cleanedName === "usa" ||
    cleanedName === "united states of america" ||
    cleanedName === "united states" ||
    cleanedName === "usa" ||
    cleanedName === "united states of america" ||
    cleanedName === "united states" ||
    cleanedName === "CA" || // California
    cleanedName === "NY" || // New York
    cleanedName === "VA" || // Virginia
    cleanedName === "TX" || // Texas
    cleanedName === "FL" || // Florida
    cleanedName === "IL" || // Illinois
    cleanedName === "PA" || // Pennsylvania
    cleanedName === "OH" || // Ohio
    cleanedName === "MI" || // Michigan
    cleanedName === "WI" || // Wisconsin
    cleanedName === "GA" || // Georgia
    cleanedName === "NC" || // North Carolina
    cleanedName === "SC" || // South Carolina
    cleanedName === "TN" || // Tennessee
    cleanedName === "KY" || // Kentucky
    cleanedName === "AL" || // Alabama
    cleanedName === "MS" || // Mississippi
    cleanedName === "AR" || // Arkansas
    cleanedName === "LA" || // Louisiana
    cleanedName === "OK" || // Oklahoma
    cleanedName === "MO" || // Missouri
    cleanedName === "AK" || // Alaska
    cleanedName === "AZ" || // Arizona
    cleanedName === "CO" || // Colorado
    cleanedName === "CT" || // Connecticut
    cleanedName === "DE" || // Delaware
    cleanedName === "HI" || // Hawaii
    cleanedName === "ID" || // Idaho
    cleanedName === "IN" || // Indiana
    cleanedName === "IA" || // Iowa
    cleanedName === "KS" || // Kansas
    cleanedName === "ME" || // Maine
    cleanedName === "MD" || // Maryland
    cleanedName === "MA" || // Massachusetts
    cleanedName === "MN" || // Minnesota
    cleanedName === "MT" || // Montana
    cleanedName === "NE" || // Nebraska
    cleanedName === "NV" || // Nevada
    cleanedName === "NH" || // New Hampshire
    cleanedName === "NJ" || // New Jersey
    cleanedName === "NM" || // New Mexico
    cleanedName === "ND" || // North Dakota
    cleanedName === "OR" || // Oregon
    cleanedName === "RI" || // Rhode Island
    cleanedName === "SD" || // South Dakota
    cleanedName === "UT" || // Utah
    cleanedName === "VT" || // Vermont
    cleanedName === "WA" || // Washington
    cleanedName === "WV" || // West Virginia
    cleanedName === "WY" || // Wyoming
    cleanedName === "usa" ||
    cleanedName === "united states of america" ||
    cleanedName === "united states" ||
    cleanedName === "ca" || // California
    cleanedName === "ny" || // New York
    cleanedName === "va" || // Virginia
    cleanedName === "tx" || // Texas
    cleanedName === "fl" || // Florida
    cleanedName === "il" || // Illinois
    cleanedName === "pa" || // Pennsylvania
    cleanedName === "oh" || // Ohio
    cleanedName === "mi" || // Michigan
    cleanedName === "wi" || // Wisconsin
    cleanedName === "ga" || // Georgia
    cleanedName === "nc" || // North Carolina
    cleanedName === "sc" || // South Carolina
    cleanedName === "tn" || // Tennessee
    cleanedName === "ky" || // Kentucky
    cleanedName === "al" || // Alabama
    cleanedName === "ms" || // Mississippi
    cleanedName === "ar" || // Arkansas
    cleanedName === "la" || // Louisiana
    cleanedName === "ok" || // Oklahoma
    cleanedName === "mo" || // Missouri
    cleanedName === "ak" || // Alaska
    cleanedName === "az" || // Arizona
    cleanedName === "co" || // Colorado
    cleanedName === "ct" || // Connecticut
    cleanedName === "de" || // Delaware
    cleanedName === "hi" || // Hawaii
    cleanedName === "id" || // Idaho
    cleanedName === "in" || // Indiana
    cleanedName === "ia" || // Iowa
    cleanedName === "ks" || // Kansas
    cleanedName === "me" || // Maine
    cleanedName === "md" || // Maryland
    cleanedName === "ma" || // Massachusetts
    cleanedName === "mn" || // Minnesota
    cleanedName === "mt" || // Montana
    cleanedName === "ne" || // Nebraska
    cleanedName === "nv" || // Nevada
    cleanedName === "nh" || // New Hampshire
    cleanedName === "nj" || // New Jersey
    cleanedName === "nm" || // New Mexico
    cleanedName === "nd" || // North Dakota
    cleanedName === "or" || // Oregon
    cleanedName === "ri" || // Rhode Island
    cleanedName === "sd" || // South Dakota
    cleanedName === "ut" || // Utah
    cleanedName === "vt" || // Vermont
    cleanedName === "wa" || // Washington
    cleanedName === "wv" || // West Virginia
    cleanedName === "wy" ||
    cleanedName === "usa" ||
    cleanedName === "united states" ||
    cleanedName === "united states of america" ||
    cleanedName === "us" ||
    cleanedName === "United States Only" ||
    cleanedName === "united states only"
  ) {
    return "United States";
  }

  if (cleanedName === "multiple locations") {
    return "Multiple Locations";
  }

  if (cleanedName === "remote") {
    return "Remote";
  }

  return cleanedName;
};

export const postedOnOptions = [
  { value: "today", label: "Today" },
  { value: "last_7_days", label: "Last 7 days" },
  { value: "last_30_days", label: "Last 30 days" },
  { value: "last_3_months", label: "Last 3 months" },
];

export const salaryOptions = [
  { value: null, label: "No salary information" },
  { value: "less_1000", label: "Less than $1,000" },
  { value: "1000_2000", label: "$1,000 to $2,000" },
  { value: "2000_3000", label: "$2,000 to $3,000" },
  { value: "3000_4000", label: "$3,000 to $4,000" },
  { value: "4000_5000", label: "$4,000 to $5,000" },
  { value: "more_5000", label: "More than $5,000" },
];

export const allowedCountries = [
  "Albania",
  "Algeria",
  "Brazil",
  "Canada",
  "China",
  "Colombia",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Egypt",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "India",
  "Indonesia",
  "Ireland",
  "Israel",
  "Italy",
  "Japan",
  "Jordan",
  "Kenya",
  "Malaysia",
  "Mexico",
  "Morocco",
  "Netherlands",
  "New Zealand",
  "Nigeria",
  "Norway",
  "Pakistan",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "South Africa",
  "South Korea",
  "Spain",
  "Sweden",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Tunisia",
  "Turkey",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Vietnam",
];
