import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePlan } from "../contexts/PlanContext";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Header = ({ isAuthenticated, setIsAuthenticated }) => {
  const { setFromSingleButton } = usePlan();
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignOut = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/signin"); // Redirect to sign-in page or wherever appropriate
  };

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleNavigation = () => {
    if (location.pathname === "/insights") {
      sessionStorage.setItem("previousPath", "/insights");
      navigate("/");
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <nav className="navbar text-white flex flex-col lg:flex-row lg:justify-between items-center lg:items-center relative border-t border-b border-white">
      <div className="flex flex-1"></div>
      <div className="flex flex-1 justify-between items-center w-full">
        <h1
          className="title w-full text-center lg:text-left lg:relative lg:right-12 cursor-pointer"
          onClick={handleNavigation}
        >
          AI JOBS
        </h1>

        {/* Botón del menú hamburguesa para móviles */}
        <div className="lg:hidden flex items-center absolute right-4 top-1/2 transform -translate-y-1/2">
          <button
            className="hamburger-menu-button p-2"
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? (
              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
            ) : (
              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
            )}
          </button>
        </div>

        {/* Botones de autenticación para pantallas grandes */}
        <div className="hidden lg:flex flex-col lg:flex-row gap-y-4 w-min">
          {!isAuthenticated ? (
            <>
              {location.pathname !== "/signin" && (
                <Link
                  to="/signin"
                  className="sign-in button gradient-input rounded-3xl w-full lg:w-auto text-sm py-1 px-1 relative -right-1"
                  aria-label="Go to Log In"
                >
                  Log In
                </Link>
              )}
              {location.pathname !== "/signup" && (
                <Link
                  onClick={() => setFromSingleButton(true)}
                  to="/signup"
                  className="sign-up button gradient-input rounded-3xl w-full lg:w-auto text-md py-1 px-1"
                  aria-label="Go to Sign Up"
                >
                  Sign Up
                </Link>
              )}
            </>
          ) : (
            <button
              className="button gradient-input rounded-3xl w-full lg:w-auto text-md py-1 px-1"
              onClick={handleSignOut}
            >
              Sign Out
            </button>
          )}
        </div>
      </div>

      {/* Menú desplegable para móviles */}
      {isMobileMenuOpen && (
        <div className="lg:hidden flex flex-col items-center bg-gray-800 text-white rounded-lg absolute top-full left-0 right-0 mt-2 p-4 z-50">
          {!isAuthenticated ? (
            <>
              {location.pathname !== "/signin" && (
                <Link
                  to="/signin"
                  className="lg:button lg:gradient-input rounded-3xl w-full mb-2 text-blue-500 text-center"
                  onClick={() => {
                    handleSignOut();
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Log In
                </Link>
              )}
              {location.pathname !== "/signup" && (
                <Link
                  to="/signup"
                  className="lg:button lg:gradient-input rounded-3xl w-full text-blue-500 text-center"
                  aria-label="Go to Sign Up"
                  onClick={() => {
                    setFromSingleButton(true);
                    setIsMobileMenuOpen(false);
                  }}
                >
                  Sign Up
                </Link>
              )}
            </>
          ) : (
            <button
              className="lg:button lg:gradient-input rounded-3xl w-full"
              onClick={() => {
                setIsAuthenticated(false);
                setIsMobileMenuOpen(false);
              }}
            >
              Sign Out
            </button>
          )}
        </div>
      )}
    </nav>
  );
};

export default Header;
