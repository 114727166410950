import React, { useState, useEffect } from "react";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    } else if (consent === "accepted") {
      initializeAnalytics();
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    window.dispatchEvent(new Event("storage"));
    setShowBanner(false);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "consent_given", consentGranted: true });
    initializeAnalytics();
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    window.dispatchEvent(new Event("storage"));
    setShowBanner(false);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: "consent_denied", consentGranted: false });
    // console.log("User declined cookies, analytics not initialized");
  };

  const initializeAnalytics = () => {
    if (window.gtag) {
      window.gtag("config", "G-83T1SG9J4P", {
        anonymize_ip: true,
        allow_google_signals: false,
      });
    }
  };

  return (
    <>
      {showBanner && (
        <div style={bannerStyle}>
          <p>
            We use cookies to improve your experience on our website. By
            continuing to navigate, you agree to our use of cookies.
          </p>
          <div>
            <button
              onClick={handleAccept}
              style={buttonStyle}
              className="bg-green-900"
            >
              Accept Cookies
            </button>
            <button
              onClick={handleDecline}
              style={buttonStyle}
              className="bg-red-900"
            >
              Reject Cookies
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const bannerStyle = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  backgroundColor: "#000",
  color: "#fff",
  padding: "20px",
  textAlign: "center",
  zIndex: 90000,
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  opacity: 0.9,
};

const buttonStyle = {
  marginLeft: "20px",
  padding: "5px 10px",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default CookieConsent;
