// TelegramLoginWidget.js
import React, { useEffect, useRef } from "react";
import { usePlan } from "../contexts/PlanContext";
import axios from "axios";

const TelegramLoginWidget = ({ botName }) => {
  const telegramWrapperRef = useRef(null);
  const { setTelegramUser, serverUrl, setToken } = usePlan();

  useEffect(() => {
    const currentRef = telegramWrapperRef.current;

    // Cargar el script del widget de Telegram
    const scriptElement = document.createElement("script");
    scriptElement.src = "https://telegram.org/js/telegram-widget.js?22";
    scriptElement.setAttribute("data-telegram-login", botName);
    scriptElement.setAttribute("data-size", "large");
    scriptElement.setAttribute("data-request-access", "write");
    // En lugar de auth-url, usa `data-onauth`
    scriptElement.setAttribute(
      "data-onauth",
      "window.handleTelegramAuth(user)"
    );
    scriptElement.async = true;

    if (currentRef) {
      currentRef.appendChild(scriptElement);
    }

    // Definir la función que intercepta la respuesta de Telegram
    window.handleTelegramAuth = async (user) => {
      alert("Authentication successful!");
      setTelegramUser(user);

      try {
        const response = await axios.post(`${serverUrl}/api/telegram`, user);
        const { token } = response.data;

        if (token) {
          setToken(token);
          localStorage.setItem("token", token);
        } else {
          alert("Token not found in response.");
        }
      } catch (error) {
        console.error(
          "Error al obtener el token de autenticación:",
          error.response || error
        );
        alert("Authentication failed. Try again.");
      }
    };
    return () => {
      if (currentRef) {
        currentRef.innerHTML = "";
      }
      delete window.handleTelegramAuth; // Limpia la función global
    };
  }, [botName, setTelegramUser]);

  return (
    <div
      ref={telegramWrapperRef}
      className="telegram-login flex items-center justify-center"
    ></div>
  );
};

export default TelegramLoginWidget;
