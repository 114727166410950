import { usePlan } from "../contexts/PlanContext";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

const TelegramCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setToken } = usePlan();

  useEffect(() => {
    const authenticateTelegramUser = async () => {
      const params = new URLSearchParams(location.search);
      const authData = {};

      for (const [key, value] of params.entries()) {
        authData[key] = value;
      }

      try {
        const response = await axios.post(
          "https://jobs-server-but9.onrender.com/api/telegram",
          authData
        );

        if (response.data.success) {
          const { token } = response.data;
          setToken(token);
          localStorage.setItem("token", token);
          alert("Load your CV");
          navigate("/info");
        }
      } catch (error) {
        console.error("Error en la autenticación de Telegram:", error);
        alert("Authentication failed. Try again.");
        navigate("/auth/telegram/failure");
      }
    };

    authenticateTelegramUser();
  }, [location, navigate, setToken]);

  return <div>Autenticando...</div>;
};

export default TelegramCallback;
