import React from "react";
import Select from "react-select";

const FiltersModal = ({
  onClose,
  handleCountryChange,
  handleCategoryChange,
  handleTechnologyChange,
  technologies,
  customStyles,
  selectedTechnologyOptions,
  countryOptions,
  selectedCountryOptions,
  categoryOptions,
  selectedCategoryOptions,
  postedOnOptions,
  salaryOptions,
  postedOn,
  handlePostedOnChange,
  selectedSalaryRange,
  handleSalaryRangeChange,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 flex ">
      <div className="bg-white w-11/12 max-w-md p-4 rounded-lg">
        <h2 className="text-xl font-bold mb-4  text-center">Filters</h2>
        {/* Tus inputs de filtros */}
        <div className="flex flex-col gap-4 justify-center items-center">
          <Select
            isMulti
            name="technologies"
            options={technologies.map((tech) => ({
              value: tech.charAt(0).toUpperCase() + tech.slice(1),
              label: tech.charAt(0).toUpperCase() + tech.slice(1),
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleTechnologyChange}
            placeholder="Technologies"
            styles={{
              ...customStyles,
              multiValue: (provided) => ({ ...provided, display: "none" }),
              multiValueLabel: (provided) => ({ ...provided, display: "none" }),
              multiValueRemove: (provided) => ({
                ...provided,
                display: "none",
              }),
            }}
            value={selectedTechnologyOptions}
            controlShouldRenderValue={false}
            aria-label="Select Technologies"
          />
          <Select
            isMulti
            name="countries"
            options={countryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleCountryChange}
            placeholder="Countries"
            styles={{
              ...customStyles,
              multiValue: (provided) => ({ ...provided, display: "none" }),
              multiValueLabel: (provided) => ({ ...provided, display: "none" }),
              multiValueRemove: (provided) => ({
                ...provided,
                display: "none",
              }),
            }}
            value={selectedCountryOptions}
            controlShouldRenderValue={false}
            aria-label="Select Countries"
          />
          <Select
            isMulti
            name="categories"
            options={categoryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleCategoryChange}
            placeholder="Categories"
            styles={{
              ...customStyles,
              multiValue: (provided) => ({ ...provided, display: "none" }),
              multiValueLabel: (provided) => ({ ...provided, display: "none" }),
              multiValueRemove: (provided) => ({
                ...provided,
                display: "none",
              }),
            }}
            value={selectedCategoryOptions}
            controlShouldRenderValue={false}
            aria-label="Select Categories"
          />
          <Select
            name="postedOn"
            options={postedOnOptions}
            className="basic-select"
            classNamePrefix="select"
            onChange={handlePostedOnChange}
            placeholder="Posted On"
            styles={customStyles}
            value={postedOn}
            isClearable
            aria-label="Select Posted On"
          />
          <Select
            name="salaryRange"
            options={salaryOptions}
            className="basic-select"
            classNamePrefix="select"
            onChange={handleSalaryRangeChange}
            placeholder="Monthly Salary"
            styles={customStyles}
            value={selectedSalaryRange}
            isClearable
            aria-label="Monthly Salary"
          />
        </div>
        <div className="flex justify-center mt-10">
          <button
            className="bg-black opacity-90 text-white px-4 py-2 rounded-lg"
            onClick={onClose}
          >
            Apply filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default FiltersModal;
