import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import { Helmet } from "react-helmet";
import { FixedSizeList as List } from "react-window";
import "./../App.css";
import { usePlan } from "../contexts/PlanContext";
import { countries2, customStyles, categories2, capitalize } from "./constants";
import ClipLoader from "react-spinners/ClipLoader";
import FiltersModal from "./FilterModal";
import JobRow from "./JobRow";
import StickySignInPrompt from "./StickyDivSignInPrompt";
import { postedOnOptions, salaryOptions } from "./constants/index";
import FiltersForm from "./FiltersForm";
import { useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";

const CACHE_EXPIRATION_TIME = 240 * 60 * 1000;

const JobList = ({ isAuthenticated }) => {
  // const location = useLocation();
  // const prefilteredJobs = location.state?.prefilteredJobs;

  const location = useLocation();

  const isScoutOrigin = location.pathname === "/scout";

  const {
    serverUrl,
    mixOfClickedOptions,
    setSelectedCountriesFromList,
    setJobsFetched,
    setClickedCountries,
    setSelectedCategoriesFromList,
    setmixOfClickedOptions,
    setClickedCategories,
    clickedTechnologies,
    setClickedTechnologies,
    clickedCountries,
    clickedCategories,
    visibleBlurredJobs,
    prefilteredJobs,
    jobsFetched,
  } = usePlan();

  const baseUrl = serverUrl;
  // const baseUrl = "http://localhost:5000";
  const [jobs, setJobs] = useState(jobsFetched || []);
  const [name, setName] = useState("");
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const [loading, setLoading] = useState(
    !jobsFetched || jobsFetched.length === 0
  );
  const [postedOn, setPostedOn] = useState(null);
  const [showStickyDiv, setShowStickyDiv] = useState(false);

  const [selectedSalaryRange, setSelectedSalaryRange] = useState(null);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [containerHeight, setContainerHeight] = useState(0);
  const rowHeight = 270;
  const hideThreshold = rowHeight * 25;
  const [technologies, setTechnologies] = useState([]);
  const [selectedTechnologyOptions, setSelectedTechnologyOptions] = useState(
    []
  );

  const updateMixOfClickedOptions = useCallback(
    (type, values, label) => {
      setmixOfClickedOptions((prev) => {
        const otherOptions = prev.filter((opt) => opt.type !== type);
        const newOptions = values.map((value) => ({ type, value, label }));
        return [...otherOptions, ...newOptions];
      });
    },
    [setmixOfClickedOptions]
  );

  const handleCountryChange = useCallback(
    (selectedOptions) => {
      const selectedCountriesFromSelect = selectedOptions
        ? selectedOptions.map((option) => option.value).filter(Boolean)
        : [];

      setSelectedCountryOptions((prevSelectedOptions) => {
        const previousCountries = prevSelectedOptions.map(
          (option) => option.value
        );
        const newCombinedCountries = [
          ...previousCountries,
          ...selectedCountriesFromSelect,
        ];

        // Remove duplicates from the combined list of countries
        const uniqueCountries = Array.from(new Set(newCombinedCountries));

        // Return options in the format { value, label } for Select
        return uniqueCountries.map((value) => ({
          value,
          label: value,
        }));
      });

      // Combine previously clicked countries with the new selections
      setClickedCountries((prevClickedCountries) => {
        const combinedCountries = [
          ...prevClickedCountries.map((c) => c.value),
          ...selectedCountriesFromSelect,
        ];

        // Remove duplicates and format for the context
        const uniqueClickedCountries = Array.from(
          new Set(combinedCountries)
        ).map((value) => ({
          type: "country",
          value,
        }));

        return uniqueClickedCountries;
      });

      // Update mixOfClickedOptions by merging new and previous selections
      updateMixOfClickedOptions("country", [
        ...clickedCountries.map((c) => c.value),
        ...selectedCountriesFromSelect,
      ]);
    },
    [clickedCountries, setClickedCountries, updateMixOfClickedOptions]
  );

  const handleCategoryChange = useCallback(
    (selectedOptions) => {
      const selectedCategoriesFromSelect = selectedOptions
        ? selectedOptions.map((option) => option.value).filter(Boolean)
        : [];

      // Actualizar el estado de las categorías seleccionadas en el Select
      setSelectedCategoryOptions((prevSelectedOptions) => {
        const previousCategories = prevSelectedOptions.map(
          (option) => option.value
        );
        const newCombinedCategories = [
          ...previousCategories,
          ...selectedCategoriesFromSelect,
        ];

        // Eliminar duplicados
        const uniqueCategories = Array.from(new Set(newCombinedCategories));

        // Devolver opciones en formato { value, label } para Select
        return uniqueCategories.map((value) => ({
          value,
          label: value,
        }));
      });

      // Combinar categorías previamente seleccionadas con las nuevas selecciones
      setClickedCategories((prevClickedCategories) => {
        const combinedCategories = [
          ...prevClickedCategories.map((c) => c.value),
          ...selectedCategoriesFromSelect,
        ];

        // Eliminar duplicados y formatear para el contexto
        const uniqueClickedCategories = Array.from(
          new Set(combinedCategories)
        ).map((value) => ({
          type: "category",
          value,
        }));

        return uniqueClickedCategories;
      });

      // Actualizar `mixOfClickedOptions` acumulando las nuevas selecciones
      updateMixOfClickedOptions("category", [
        ...clickedCategories.map((c) => c.value),
        ...selectedCategoriesFromSelect,
      ]);
    },
    [clickedCategories, setClickedCategories, updateMixOfClickedOptions]
  );

  const handleTechnologyChange = useCallback(
    (selectedOptions) => {
      const selectedTechnologiesFromSelect = selectedOptions
        ? selectedOptions.map((option) => option.value).filter(Boolean)
        : [];

      setClickedTechnologies((prevClickedTechnologies) => {
        const combinedTechnologies = [
          ...prevClickedTechnologies.map((t) => t.value),
          ...selectedTechnologiesFromSelect,
        ];

        // Eliminar duplicados
        const uniqueClickedTechnologies = Array.from(
          new Set(combinedTechnologies)
        ).map((value) => ({
          type: "technology",
          value,
        }));

        return uniqueClickedTechnologies;
      });

      // Actualizar `mixOfClickedOptions`
      updateMixOfClickedOptions("technology", [
        ...clickedTechnologies.map((t) => t.value),
        ...selectedTechnologiesFromSelect,
      ]);
    },
    [clickedTechnologies, setClickedTechnologies, updateMixOfClickedOptions]
  );

  const handlePostedOnChange = useCallback(
    (selectedOption) => {
      setPostedOn(selectedOption);
      updateMixOfClickedOptions(
        "postedOn",
        selectedOption ? [selectedOption.value] : [],
        selectedOption ? selectedOption.label : ""
      );
    },
    [updateMixOfClickedOptions]
  );

  const handleSalaryRangeChange = useCallback(
    (selectedOption) => {
      setSelectedSalaryRange(selectedOption);
      updateMixOfClickedOptions(
        "salaryRange",
        selectedOption ? [selectedOption.value] : [],
        selectedOption ? selectedOption.label : ""
      );
    },
    [updateMixOfClickedOptions]
  );

  const handleRemoveFilter = (type, value, label) => {
    setmixOfClickedOptions((prev) =>
      prev.filter((opt) => {
        if (label === "No salary information") {
          return opt.value !== null;
        }
        return !(opt.type === type && opt.value === value);
      })
    );

    if (type === "country") {
      setSelectedCountryOptions((prev) =>
        prev.filter((option) => option.value !== value)
      );
      setClickedCountries((prev) =>
        prev.filter((country) => country.value !== value)
      );
      setSelectedCountriesFromList((prev) =>
        prev.filter((country) => country !== value)
      );
    } else if (type === "category") {
      setSelectedCategoryOptions((prev) =>
        prev.filter((option) => option.value !== value)
      );
      setClickedCategories((prev) =>
        prev.filter((category) => category.value !== value)
      );
      setSelectedCategoriesFromList((prev) =>
        prev.filter((category) => category !== value)
      );
    } else if (type === "technology") {
      setClickedTechnologies((prev) =>
        prev.filter((tech) => tech.value !== value)
      );
    } else if (type === "postedOn") {
      setPostedOn(null);
    } else if (type === "salaryRange") {
      // Para el filtro "No salary information", reseteamos el estado de salaryRange
      setSelectedSalaryRange(null);
    }
  };

  useEffect(() => {
    const removeFullInnerText = (jobs) => {
      return jobs.map((job) => {
        const { full_inner_text, ...rest } = job;
        return rest;
      });
    };

    const fetchFilteredJobs = async () => {
      setLoading(true);
      if (prefilteredJobs) {
        const sanitizedJobs = removeFullInnerText(prefilteredJobs);
        setJobs(sanitizedJobs);
      }
      setLoading(false);
    };

    const fetchInitialJobs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${baseUrl}/api/jobs`);
        const initialJobs = response.data.reverse();
        const sortedJobs = initialJobs.sort((a, b) => {
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          return dateB - dateA;
        });
        const sanitizedJobs = removeFullInnerText(sortedJobs);
        setJobs(sanitizedJobs);
      } catch (error) {
        console.error("Error fetching initial jobs:", error);
      }
      setLoading(false);
    };

    const fetchRemainingJobs = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/jobs/remaining`);
        const remainingJobs = response.data.reverse();
        const sanitizedJobs = removeFullInnerText(remainingJobs);

        setJobs((prevJobs) => {
          const allJobs = [...prevJobs, ...sanitizedJobs];
          const uniqueJobs = Array.from(
            new Set(allJobs.map((job) => job._id))
          ).map((id) => allJobs.find((job) => job._id === id));
          return uniqueJobs;
        });
      } catch (error) {
        console.error("Error fetching remaining jobs:", error);
      }
    };

    const fetchInitialAndRemainingJobs = async () => {
      await fetchInitialJobs();
      await fetchRemainingJobs();
    };

    if (prefilteredJobs && prefilteredJobs.length > 0) {
      fetchFilteredJobs();
    } else {
      fetchInitialAndRemainingJobs();
      const intervalId = setInterval(() => {
        fetchInitialAndRemainingJobs();
      }, CACHE_EXPIRATION_TIME);
      return () => clearInterval(intervalId);
    }
  }, [baseUrl, prefilteredJobs]);

  useEffect(() => {
    setJobsFetched(jobs);
  }, [jobs, setJobsFetched]);

  useEffect(() => {
    setCountries(countries2);
    setCategories(categories2);
    setTechnologies((prev) => {
      const techFromJobs = jobs.map((job) => job.stack);
      const uniqueTechFromJobs = new Set(techFromJobs.flat());
      return Array.from(uniqueTechFromJobs);
    });
  }, [jobs]);

  const debouncedSetName = debounce((value) => setName(value), 300);

  const handleNameChange = (event) => debouncedSetName(event.target.value);

  const countryOptions = useMemo(
    () =>
      countries.map((country) => ({
        value: country,
        label: country,
      })),
    [countries]
  );

  const categoryOptions = useMemo(
    () =>
      categories.map((category) => ({
        value: category,
        label: category,
      })),
    [categories]
  );

  const combinedFilters = useMemo(() => {
    const mixSet = new Set(
      mixOfClickedOptions.map(
        (item) => `${item.type}-${item.value}-${item.label || item.value}` // Handle undefined labels
      )
    );

    const additionalCategoryFilters = selectedCategoryOptions
      .filter((category) => category.value)
      .filter((category) => !mixSet.has(`category-${category.value}`))
      .map((category) => ({
        type: "category",
        value: category.value,
        label: category.label || category.value, // Set label to value if label is missing
      }));

    const additionalCountryFilters = selectedCountryOptions
      .filter((country) => country.value)
      .filter((country) => !mixSet.has(`country-${country.value}`))
      .map((country) => ({
        type: "country",
        value: country.value,
        label: country.label || country.value, // Set label to value if label is missing
      }));

    const additionalTechnologyFilters = clickedTechnologies
      .filter((tech) => tech.value)
      .filter((tech) => !mixSet.has(`technology-${tech.value}`))
      .map((tech) => ({
        type: "technology",
        value: tech.value,
        label: tech.label || tech.value, // Set label to value if label is missing
      }));

    // Use a Set to ensure no duplicate filters
    const uniqueFilters = new Set(
      [
        ...mixOfClickedOptions,
        ...additionalCategoryFilters,
        ...additionalCountryFilters,
        ...additionalTechnologyFilters,
      ].map(
        (filter) =>
          `${filter.type}-${filter.value}-${filter.label || filter.value}`
      )
    );

    // Convert back to an array of objects after filtering duplicates
    return Array.from(uniqueFilters).map((filter) => {
      const [type, value, label] = filter.split("-");
      return { type, value, label: label || value }; // Ensure label is present
    });
  }, [
    mixOfClickedOptions,
    selectedCategoryOptions,
    selectedCountryOptions,
    clickedTechnologies,
  ]);

  const finalFilteredJobs = useMemo(() => {
    let result = jobs;
    const filters = {
      countries: new Set(),
      technologies: new Set(),
      companies: new Set(),
      categories: new Set(),
    };

    // Añadir opciones seleccionadas en los filtros
    mixOfClickedOptions.forEach((option) => {
      switch (option.type) {
        case "country":
          filters.countries.add(option.value.toLowerCase());
          break;
        case "technology":
          filters.technologies.add(option.value.toLowerCase());
          break;
        case "category":
          filters.categories.add(option.value.toLowerCase());
          break;
        case "company":
          filters.companies.add(option.value.toLowerCase());
          break;
        default:
          break;
      }
    });

    // Filtrar trabajos por países si se seleccionaron
    if (filters.countries.size > 0) {
      result = result.filter(
        (job) => job.country && filters.countries.has(job.country.toLowerCase())
      );
    }

    // Filtrar trabajos por tecnologías seleccionadas
    if (filters.technologies.size > 0) {
      result = result.filter((job) => {
        if (!job.stack || !Array.isArray(job.stack)) return false;

        // Mostrar trabajos que contengan **alguna** tecnología seleccionada
        return Array.from(filters.technologies).some((selectedTech) =>
          job.stack.some((tech) => tech.toLowerCase() === selectedTech)
        );
      });
    }

    // Filtrar trabajos por categorías si se seleccionaron
    if (filters.categories.size > 0) {
      result = result.filter(
        (job) =>
          job.category && filters.categories.has(job.category.toLowerCase())
      );
    }

    // Filtrar trabajos por compañías si se seleccionaron
    if (filters.companies.size > 0) {
      result = result.filter(
        (job) =>
          job.company_name &&
          filters.companies.has(job.company_name.toLowerCase())
      );
    }

    // Filtrar trabajos por nombre si se ha introducido
    if (name) {
      result = result.filter(
        (job) =>
          job.job_name &&
          job.job_name.toLowerCase().includes(name.toLowerCase())
      );
    }

    // Filtrar trabajos por fecha de publicación (postedOn)
    if (postedOn) {
      const now = new Date();
      result = result.filter((job) => {
        if (!job.timestamp) return false;
        const jobDate = new Date(job.timestamp);
        const timeDiff = now.getTime() - jobDate.getTime();
        const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

        switch (postedOn.value) {
          case "today":
            return daysDiff === 0;
          case "last_7_days":
            return daysDiff < 7;
          case "last_30_days":
            return daysDiff < 30;
          case "last_3_months":
            return daysDiff < 90;
          default:
            return true;
        }
      });
    }

    // Filtrar trabajos por rango salarial
    if (selectedSalaryRange) {
      result = result.filter((job) => {
        const { min_salary, max_salary } = job;

        // Manejar "No salary information"
        if (selectedSalaryRange.value === null) {
          // Mostrar solo trabajos con `min_salary` y `max_salary` como `null`
          return min_salary === null && max_salary === null;
        }

        // Excluir trabajos sin información salarial
        if (min_salary === null || max_salary === null) {
          return false;
        }

        // Convertir salario anual a mensual
        const minMonthlySalary = min_salary / 12;
        const maxMonthlySalary = max_salary / 12;

        let selectedMin, selectedMax;

        switch (selectedSalaryRange.value) {
          case "less_1000":
            selectedMin = 0;
            selectedMax = 1000;
            break;
          case "1000_2000":
            selectedMin = 1000;
            selectedMax = 2000;
            break;
          case "2000_3000":
            selectedMin = 2000;
            selectedMax = 3000;
            break;
          case "3000_4000":
            selectedMin = 3000;
            selectedMax = 4000;
            break;
          case "4000_5000":
            selectedMin = 4000;
            selectedMax = 5000;
            break;
          case "more_5000":
            selectedMin = 5000;
            selectedMax = Infinity;
            break;
          default:
            return true;
        }

        // Verificar si hay solapamiento entre los rangos
        return (
          minMonthlySalary <= selectedMax && maxMonthlySalary >= selectedMin
        );
      });
    }

    return result;
  }, [jobs, mixOfClickedOptions, name, postedOn, selectedSalaryRange]);

  useEffect(() => {
    const calculatedHeight = finalFilteredJobs.length * rowHeight;
    const availableHeight = window.innerHeight;
    setContainerHeight(
      calculatedHeight > availableHeight ? availableHeight : calculatedHeight
    );
  }, [finalFilteredJobs.length]);

  const handleScroll = ({ scrollOffset }) => {
    // Ocultar sticky div si estamos dentro del threshold
    if (scrollOffset <= hideThreshold) {
      setShowStickyDiv(false);
    }

    // Desplazamiento programático hacia el final del documento
    if (scrollOffset > rowHeight) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }

    // Desplazamiento programático hacia el inicio del documento
    if (scrollOffset === 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (visibleBlurredJobs > 0) {
      setShowStickyDiv(true);
    }
  }, [visibleBlurredJobs]);

  return (
    <div className="flex flex-col md:items-center justify-center mb-6 flex flex-col mb-6 justify-center ">
      <Helmet>
        <title>AI Jobs - Find Your Next Job in AI and Tech.</title>
        <meta
          name="description"
          content="Browse the latest job openings in AI and tech. Sign up to see exclusive jobs and apply now."
        />
      </Helmet>

      <main className="jobList--main">
        {/* Botón de filtros para móviles */}
        <button
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-4 rounded-full lg:hidden"
          onClick={() => setIsFiltersOpen(true)}
        >
          Filters
        </button>
        <FiltersForm
          handleNameChange={handleNameChange}
          handleTechnologyChange={handleTechnologyChange}
          handleCountryChange={handleCountryChange}
          handleCategoryChange={handleCategoryChange}
          handlePostedOnChange={handlePostedOnChange}
          handleSalaryRangeChange={handleSalaryRangeChange}
          selectedTechnologyOptions={selectedTechnologyOptions}
          selectedCountryOptions={selectedCountryOptions}
          selectedCategoryOptions={selectedCategoryOptions}
          selectedSalaryRange={selectedSalaryRange}
          countryOptions={countryOptions}
          categoryOptions={categoryOptions}
          technologies={technologies}
        />
        {/* Modal de filtros para móviles */}
        {isFiltersOpen && (
          <FiltersModal
            onClose={() => setIsFiltersOpen(false)}
            handleCountryChange={handleCountryChange}
            handleCategoryChange={handleCategoryChange}
            handleTechnologyChange={handleTechnologyChange}
            technologies={technologies}
            customStyles={customStyles}
            selectedTechnologyOptions={selectedTechnologyOptions}
            countryOptions={countryOptions}
            selectedCountryOptions={selectedCountryOptions}
            categoryOptions={categoryOptions}
            selectedCategoryOptions={selectedCategoryOptions}
            postedOnOptions={postedOnOptions}
            salaryOptions={salaryOptions}
            postedOn={postedOn}
            handlePostedOnChange={handlePostedOnChange}
            selectedSalaryRange={selectedSalaryRange}
            handleSalaryRangeChange={handleSalaryRangeChange}
          />
        )}

        <section className="selected-filters text-white text-center rounded-xl p-4 mb-1">
          {combinedFilters.length > 0 ? (
            <div>
              <ul className="flex flex-wrap justify-center">
                {combinedFilters.map((filter, index) => (
                  <li
                    key={`${filter.type}-${filter.value}-${index}`}
                    className="flex items-center rounded-full bg-gray-900 px-4 py-2 text-[15px] w-fit relative ellipsis m-1"
                  >
                    <span className="mr-2">
                      {capitalize(filter.label) || capitalize(filter.value)}
                    </span>{" "}
                    <button
                      onClick={() =>
                        handleRemoveFilter(
                          filter.type,
                          filter.value,
                          filter.label
                        )
                      }
                      aria-label={`Remove filter: ${
                        filter.label || filter.value
                      }`}
                      className="font-bold text-xs cursor-pointer rounded-full"
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
              <p className="mt-8">{finalFilteredJobs.length} jobs found</p>
            </div>
          ) : (
            !loading && (
              <span className="text-gray-400 opacity-50">
                Select some filters...
              </span>
            )
          )}
        </section>

        <section className="w-[95%] md:w-full m-auto">
          {loading ? (
            <div className="flex items-center justify-center">
              <ClipLoader color={"#ffffff"} loading={loading} size={150} />
            </div>
          ) : (
            <ul className="custom-scroll-container hide-scrollbar">
              <List
                height={containerHeight}
                itemCount={finalFilteredJobs.length}
                itemSize={rowHeight}
                width={"100%"}
                onScroll={handleScroll}
              >
                {({ index, style }) => (
                  <JobRow
                    index={index}
                    style={style}
                    finalFilteredJobs={finalFilteredJobs}
                    isAuthenticated={isAuthenticated}
                  />
                )}
              </List>
            </ul>
          )}
        </section>

        {showStickyDiv && !isAuthenticated && <StickySignInPrompt />}
      </main>
    </div>
  );
};

export default JobList;
