import React, { Suspense, useCallback } from "react";
import { Waypoint } from "react-waypoint";
import { usePlan } from "../contexts/PlanContext";

const FREE_JOB_LIMIT = Infinity;

const JobItem = React.memo(React.lazy(() => import("./JobItem")));

export const JobRow = React.memo(
  ({ index, style, finalFilteredJobs, isAuthenticated }) => {
    const { setVisibleBlurredJobs } = usePlan();

    const job = finalFilteredJobs[index];
    const isFreeJob = index < FREE_JOB_LIMIT;

    const handleBlurredJobEnter = useCallback(() => {
      setVisibleBlurredJobs((prev) => {
        if (prev <= 0) {
          return prev + 1;
        }
        return prev; // No hace nada si prev es mayor que 0
      });
    }, []);

    const handleBlurredJobLeave = useCallback(() => {
      setVisibleBlurredJobs((prev) => {
        if (prev > 0) {
          return prev - 1;
        }
        return prev; // No hace nada si prev es 0 o menor
      });
    }, []);

    return (
      <li
        key={`${job.link} ${index}`}
        id={`job-${index}`}
        className={`job-item ${
          !isFreeJob && !isAuthenticated ? "blurred-job" : ""
        }`}
        style={{
          ...style,
          boxSizing: "border-box",
        }}
      >
        {!isFreeJob && !isAuthenticated && (
          <Waypoint
            topOffset="100px"
            bottomOffset="100px"
            onEnter={handleBlurredJobEnter}
            onLeave={handleBlurredJobLeave}
          />
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <JobItem
            job={job}
            isAuthenticated={isAuthenticated}
            isFreeJob={isFreeJob}
          />
        </Suspense>
      </li>
    );
  }
);

export default JobRow;
