import React, { createContext, useState, useContext, useEffect } from "react";

const PlanContext = createContext();

export const usePlan = () => useContext(PlanContext);

export const PlanProvider = ({ children }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [clickedCountries, setClickedCountries] = useState([]);
  const [clickedCompanies, setClickedCompanies] = useState([]);
  const [clickedCategories, setClickedCategories] = useState([]);
  const [clickedTechnologies, setClickedTechnologies] = useState([]);
  const [mixOfClickedOptions, setmixOfClickedOptions] = useState([]);
  const [fromSingleButton, setFromSingleButton] = useState(false);
  const [selectedCountriesFromList, setSelectedCountriesFromList] = useState(
    []
  );
  const [jobsFetched, setJobsFetched] = useState([]);
  const [selectedCategoriesFromList, setSelectedCategoriesFromList] = useState(
    []
  );
  const [selectedCountryOptions, setSelectedCountryOptions] = useState([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const [visibleBlurredJobs, setVisibleBlurredJobs] = useState(0);
  const [pricingPlans, setPricingPlans] = useState([]);

  const [token, setToken] = useState(localStorage.getItem("token")); // Cargar token de localStorage al
  const [telegramUser, setTelegramUser] = useState(null);

  const [prefilteredJobs, setPrefilteredJobs] = useState([]);

  useEffect(() => {}, [mixOfClickedOptions]);

  const resetFilters = () => {
    setClickedCountries([]);
    setClickedCompanies([]);
    setClickedCategories([]);
    setClickedTechnologies([]);
    setSelectedCountriesFromList([]);
    setmixOfClickedOptions([]);
    setSelectedCountryOptions([]);
    setSelectedCategoryOptions([]);
  };

  const updateMixOfClickedOptions = (type, values) => {
    setmixOfClickedOptions((prev) => {
      const existingOfType = prev.filter((opt) => opt.type === type);
      const otherOptions = prev.filter((opt) => opt.type !== type);
      console.log("otherOptions", otherOptions);

      // Combine existing options of the same type with new values
      const newOptions = [
        ...existingOfType,
        ...values.map((value) => ({ type, value })),
      ];

      console.log("newOptions", newOptions);
      return [...otherOptions, ...newOptions];
    });
  };

  const addCountry = (country) => {
    setClickedCountries((prev) => {
      const isCountryAlreadyClicked = prev.some(
        (entry) => entry.value === country
      );
      if (!isCountryAlreadyClicked) {
        return [...prev, { type: "country", value: country }];
      } else {
        return prev;
      }
    });

    setSelectedCountriesFromList((prev) => {
      if (!prev.includes(country)) {
        const updatedCountries = [...prev, country];
        updateMixOfClickedOptions("country", updatedCountries);
        return updatedCountries;
      }
      return prev;
    });
  };

  // Asegura que se puedan agregar múltiples países sin sobreescribir el último
  const addCountries = (countries) => {
    setClickedCountries((prev) => {
      const newCountries = countries.map((country) => ({
        type: "country",
        value: country,
      }));
      const mergedCountries = [...prev, ...newCountries];
      const uniqueCountries = Array.from(
        new Set(mergedCountries.map((item) => item.value))
      ).map((value) => {
        return mergedCountries.find((item) => item.value === value);
      });
      return uniqueCountries;
    });

    setSelectedCountriesFromList((prev) => {
      const mergedCountries = [...prev, ...countries];
      return Array.from(new Set(mergedCountries));
    });

    updateMixOfClickedOptions("country", countries);
  };

  const addTechnology = (technology) => {
    setClickedTechnologies((prev) => {
      const isTechnologyAlreadyClicked = prev.some(
        (entry) => entry.value === technology
      );
      if (!isTechnologyAlreadyClicked) {
        // Add new technology without overwriting previous technologies
        const newTech = [...prev, { type: "technology", value: technology }];
        updateMixOfClickedOptions(
          "technology",
          newTech.map((tech) => tech.value)
        );
        return newTech;
      } else {
        return prev;
      }
    });
  };

  // Asegura que se puedan agregar múltiples tecnologías
  const addTechnologies = (technologies) => {
    setClickedTechnologies((prev) => {
      const newTechnologies = technologies.map((technology) => ({
        type: "technology",
        value: technology,
      }));
      const mergedTechnologies = [...prev, ...newTechnologies];
      const uniqueTechnologies = Array.from(
        new Set(mergedTechnologies.map((item) => item.value))
      ).map((value) => {
        return mergedTechnologies.find((item) => item.value === value);
      });

      // Update mixOfClickedOptions with all selected technologies
      updateMixOfClickedOptions(
        "technology",
        uniqueTechnologies.map((tech) => tech.value)
      );

      return uniqueTechnologies;
    });
  };

  const addCategory = (category) => {
    setClickedCategories((prev) => {
      const isCategoryAlreadyClicked = prev.some(
        (entry) => entry.value === category
      );
      if (!isCategoryAlreadyClicked) {
        const newCategories = [...prev, { type: "category", value: category }];
        updateMixOfClickedOptions(
          "category",
          newCategories.map((cat) => cat.value)
        );
        return newCategories;
      } else {
        return prev;
      }
    });
  };

  const removeCategory = (category) => {
    setClickedCategories((prev) => {
      const newCategories = prev.filter((cat) => cat.value !== category);
      updateMixOfClickedOptions(
        "category",
        newCategories.map((cat) => cat.value)
      );
      return newCategories;
    });
  };

  useEffect(() => {
    const fetchPricingPlans = async () => {
      try {
        const fetchedPlans = [
          {
            title: "Free",
            int_ammount: "0",
            features: ["First 30 vacancies"],
            linkText: "Current Plan",
            price: null,
            old_price: null,
            id: 1,
          },
          {
            title: "5$/month",
            int_ammount: "5",
            features: ["Over ten thousand vacancies"],
            linkText: "Choose",
            price: 5,
            old_price: 10,
            id: 2,
          },
          {
            title: "30$/year",
            int_ammount: "30",
            features: ["Over ten thousand vacancies", "Advanced Insights"],
            linkText: "Choose",
            price: 30,
            old_price: 50,
            id: 3,
          },
        ];

        setPricingPlans(fetchedPlans);
      } catch (error) {
        console.error("Error fetching pricing plans:", error);
      }
    };

    fetchPricingPlans();
  }, []);

  const serverUrl = "https://jobs-server-but9.onrender.com";
  // const serverUrl = "http://localhost:5000";

  return (
    <PlanContext.Provider
      value={{
        selectedPlan,
        setSelectedPlan,
        serverUrl,
        pricingPlans,
        clickedCountries,
        setClickedCountries,
        addCountry,
        addCountries,
        clickedCompanies,
        setClickedCompanies,
        clickedCategories,
        setClickedCategories,
        clickedTechnologies,
        setClickedTechnologies,
        addTechnology,
        addTechnologies,
        mixOfClickedOptions,
        setmixOfClickedOptions,
        fromSingleButton,
        setFromSingleButton,
        resetFilters,
        selectedCountriesFromList,
        setSelectedCountriesFromList,
        jobsFetched,
        setJobsFetched,
        selectedCategoriesFromList,
        setSelectedCategoriesFromList,
        selectedCountryOptions,
        setSelectedCountryOptions,
        selectedCategoryOptions,
        setSelectedCategoryOptions,
        updateMixOfClickedOptions,
        addCategory,
        removeCategory,
        visibleBlurredJobs,
        setVisibleBlurredJobs,
        token,
        setToken,
        telegramUser,
        setTelegramUser,
        prefilteredJobs,
        setPrefilteredJobs,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};
