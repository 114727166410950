import React from "react";
import { Link } from "react-router-dom";

const StickySignInPrompt = () => {
  return (
    <div className="fixed flex bottom-0 left-0 right-0 bg-gray-900 p-4 flex items-center justify-center gap-6  h-[20vh]">
      <p className="text-white text-center">
        Sign in or register to access thousands of job offers on our portal
      </p>
      <div className="flex  justify-center">
        <Link
          to="/signin"
          className="sign-in button gradient-input rounded-3xl w-full lg:w-auto text-sm py-1 px-4"
        >
          Log In
        </Link>
        <Link
          to="/signup"
          className="sign-up button gradient-input rounded-3xl w-full lg:w-auto text-md py-1 px-4"
          aria-label="Go to Sign Up"
        >
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default StickySignInPrompt;
