import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="w-full max-w-md">
        <h1 className="text-3xl font-bold text-center">
          Something went wrong...
        </h1>
        <p className="text-center text-gray-400">
          Please try again later or contact us at{" "}
          <a
            href="mailto:aijobs@aiproductdesign.com"
            className="text-blue-500 hover:underline"
          >
            aijobs@aiproductdesign.com
          </a>
        </p>
        <Link to="/" className="text-blue-500 hover:underline">
          Go back to home
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
