import { useEffect, useState } from "react";
import { usePlan } from "../contexts/PlanContext";
import { useNavigate, useLocation } from "react-router-dom";

const SeeMoreJobsSection = ({ pricingPlans }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { setSelectedPlan, selectedPlan } = usePlan();

  const handlePlanSelection = (plan) => {
    setSelectedPlan(plan);
    navigate("/register");
  };
  const [filteredPlans, setFilteredPlans] = useState(pricingPlans);

  useEffect(() => {
    if (location.pathname === "/signup") {
      setFilteredPlans(pricingPlans.slice(1));
    } else {
      setFilteredPlans(pricingPlans);
    }
  }, [location.pathname, pricingPlans]);

  return (
    <section className="flex flex-col justify-center gap-4 p-3 pb-0 w-full text-center h-[85vh]">
      {location.pathname !== "/signup" && (
        <h1 className="text-2xl font-semibold truncate-text text-white mb-6">
          Wanna see more hand-picked jobs?
        </h1>
      )}

      <div className="pricing-container text-white flex flex-col gap-6 justify-center items-center">
        {filteredPlans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-card w-min ${
              plan.id === selectedPlan?.id ? "selected-card" : ""
            }`}
            onClick={() => handlePlanSelection(plan)}
          >
            <p className="mb-6">
              <span className="text-6xl">{plan.int_ammount}$/</span>
              {plan.int_ammount === "5" ? (
                <span className="text-xl">month</span>
              ) : (
                <span className="text-xl">Year</span>
              )}
            </p>
            <ul className="ml-6 text-xl ">
              {plan.features.map((feature, i) => (
                <li key={i}>• {feature}</li>
              ))}
            </ul>
            <button
              disabled={plan.title === "Free"}
              onClick={() => handlePlanSelection(plan)}
              className="rounded-full font-bold bg-white text-black py-2 mt-6 px-4 w-[150px]"
              style={plan.title === "Free" ? { opacity: 0.4 } : {}}
            >
              {plan.linkText}
            </button>
          </div>
        ))}
      </div>

      <p className="text-xl text-white mt-6">
        Sign up for just 5 dollars and get access to thousands of the best jobs
        in the world.
      </p>
    </section>
  );
};

export default SeeMoreJobsSection;
