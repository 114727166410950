// InfoSection.js
import axios from "axios";
import React, { useState } from "react";
import { usePlan } from "../contexts/PlanContext";
import { useNavigate } from "react-router-dom";
import TelegramLoginWidget from "./TelegramLoginButton";
import ClipLoader from "react-spinners/ClipLoader";

const InfoSection = () => {
  const { token, telegramUser, serverUrl, setPrefilteredJobs } = usePlan();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  const baseUrl = serverUrl;

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!telegramUser) {
      alert("Please, connect your Telegram account before uploading the CV.");
      return;
    }

    const formData = new FormData();
    formData.append("cv", file);
    formData.append("telegramUserId", telegramUser.id);

    setShowSpinner(true);
    try {
      const response = await axios.post(
        `${baseUrl}/api/jobs/prefiltered`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const prefilteredJobs = response.data;
      setPrefilteredJobs(prefilteredJobs);
      setShowSpinner(false);

      navigate("/board", { state: { prefilteredJobs } });
    } catch (error) {
      console.error("Error al obtener trabajos prefiltrados:", error);
      alert("There was an error processing your CV.");
    }
  };

  return (
    <div className="mt-14 bg-black text-white flex flex-col items-center justify-center px-6">
      <div className="w-full md:w-[85%] space-y-6 p-6 md:p-20 pt-0 flex flex-col items-center justify-center">
        <p className="text-lg sm:text-xl md:text-2xl text-gray-400 text-center w-full md:w-auto">
          Authorize your Telegram account to receive offers. Then, upload your
          CV and we'll send you offers directly to your Telegram bot.
        </p>

        <div className="h-8"></div>
        <div className="flex flex-col space-y-4 mt-10 w-full justify-center items-center">
          <GradientButton
            telegramUser={telegramUser}
            className="h-14 w-full"
            text="Upload CV"
            onFileUpload={handleFileUpload}
          />
          <TelegramLoginWidget botName="aijobshome_bot" />

          {!telegramUser && (
            <p className="text-red-500 text-xs text-center w-full">
              Connect your Telegram account to receive offers.
            </p>
          )}
          {showSpinner && (
            <div className="flex items-center justify-center">
              <div className="flex items-center justify-center">
                <ClipLoader
                  color={"#ffffff"}
                  loading={showSpinner}
                  size={150}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const GradientButton = ({ text, onFileUpload, telegramUser }) => {
  const inputRef = React.useRef(null);

  const handleButtonClick = () => {
    if (onFileUpload && telegramUser) {
      inputRef.current?.click();
    }
  };

  return (
    <div className="w-full max-w-md">
      <input
        type="file"
        accept="application/pdf"
        onChange={onFileUpload}
        ref={inputRef}
        style={{ display: "none" }}
      />
      <button
        disabled={!telegramUser}
        type="button"
        onClick={handleButtonClick}
        className={`w-full py-6 rounded-full text-2xl font-semibold ${
          !telegramUser ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
        }`}
        style={{
          background:
            "linear-gradient(90deg, #1D1C1F 0%, #1C1C1E 25%, #272432 50%, #322B43 75%)",
        }}
      >
        {text}
      </button>
    </div>
  );
};

export default InfoSection;
