import React, { Suspense, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import { Helmet } from "react-helmet";
import JobList from "./components/JobList";
import "./App.css";
import SeeMoreJobsSection from "./components/SeeMoreJobsSection";
import { usePlan } from "./contexts/PlanContext";
import Home from "./components/Home";
import InfoSection from "./components/Scout";
import ErrorPage from "./components/ErrorPage";
import TelegramCallback from "./components/TelegramCallback";
import TelegramFailure from "./components/TelegramFailure";

// Lazy load de los componentes
const Login = React.lazy(() => import("./components/Login"));
const Register = React.lazy(() => import("./components/Register"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy"));
const TermsOfService = React.lazy(() => import("./components/TermsOfService"));
const Insights = React.lazy(() => import("./components/Insights"));

const App = () => {
  const { pricingPlans } = usePlan();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Header
          isAuthenticated={isAuthenticated}
          setIsAuthenticated={setIsAuthenticated}
        />

        {/* Suspense envuelve las rutas que cargan de forma lazy */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/scout" element={<InfoSection />} />
            <Route
              path="/"
              element={
                <>
                  <Helmet>
                    <title>AI Jobs - Find Your Next Job in AI and Tech.</title>
                  </Helmet>
                  <Home isAuthenticated={isAuthenticated} />
                </>
              }
            ></Route>
            <Route
              path="/board"
              // path="/"
              element={
                <>
                  <Helmet>
                    <title>AI Jobs - Find Your Next Job in AI and Tech</title>
                    <meta
                      name="description"
                      content="Browse the latest job openings in AI and tech. Sign up to see exclusive jobs and apply now."
                    />
                  </Helmet>
                  <JobList isAuthenticated={isAuthenticated} />
                </>
              }
            />
            <Route
              path="/signin"
              element={
                <>
                  <Helmet>
                    <title>Sign In - AI Jobs</title>
                    <meta
                      name="description"
                      content="Sign in to access exclusive AI and tech job listings."
                    />
                  </Helmet>
                  <Login setIsAuthenticated={setIsAuthenticated} />
                </>
              }
            />
            <Route
              path="/signup"
              element={
                <>
                  <Helmet>
                    <title>Sign Up - AI Jobs</title>
                    <meta
                      name="description"
                      content="Sign up to get access to thousands of the best jobs in AI and tech."
                    />
                  </Helmet>

                  {<SeeMoreJobsSection pricingPlans={pricingPlans} />}
                </>
              }
            />
            <Route
              path="/register"
              element={
                <>
                  <Helmet>
                    <title>Register - AI Jobs</title>
                    <meta
                      name="description"
                      content="Sign up to get access to thousands of the best jobs in AI and tech."
                    />
                  </Helmet>
                  <Register setIsAuthenticated={setIsAuthenticated} />
                </>
              }
            />
            <Route
              path="/privacy-policy"
              element={
                <>
                  <Helmet>
                    <title>Privacy Policy - AI Jobs</title>
                    <meta
                      name="description"
                      content="Learn about how we collect, use, and protect your personal information at AI Jobs."
                    />
                  </Helmet>
                  <PrivacyPolicy />
                </>
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <>
                  <Helmet>
                    <title>Terms of Service - AI Jobs</title>
                    <meta
                      name="description"
                      content="Read our terms of service for using the AI Jobs website."
                    />
                  </Helmet>
                  <TermsOfService />
                </>
              }
            />

            <Route path="/insights" element={<Insights />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<div>Not Found</div>} />
            <Route
              path="/auth/telegram/callback"
              element={<TelegramCallback />}
            />

            <Route
              path="/auth/telegram/failure"
              element={<TelegramFailure />}
            />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
