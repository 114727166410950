import Select from "react-select";
import React from "react";
import { customStyles, postedOnOptions, salaryOptions } from "./constants";
import { Link } from "react-router-dom";

const FiltersForm = ({
  handleNameChange,
  handleTechnologyChange,
  handleCountryChange,
  handleCategoryChange,
  handlePostedOnChange,
  handleSalaryRangeChange,
  selectedTechnologyOptions,
  selectedCountryOptions,
  selectedCategoryOptions,
  postedOn,
  selectedSalaryRange,
  countryOptions,
  categoryOptions,
  technologies,
}) => {
  return (
    <>
      <form className="flex gap-4 m-8 justify-center filter-form items-center hidden md:flex">
        <label htmlFor="job-name" className="sr-only">
          Job Title
        </label>
        <input
          id="job-name"
          className="rounded-lg p-2 outline-none gradient-input"
          style={{
            height: "51px",
            width: "230px",
            fontSize: "1.09rem",
            fontWeight: "400",
          }}
          type="text"
          placeholder="Job Name"
          onChange={handleNameChange}
        />
        <Select
          isMulti
          name="technologies"
          options={(technologies || [])
            .filter((tech) => tech)
            .map((tech) => ({
              value: tech.charAt(0).toUpperCase() + tech.slice(1),
              label: tech.charAt(0).toUpperCase() + tech.slice(1),
            }))}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleTechnologyChange}
          placeholder="Technologies"
          styles={{
            ...customStyles,
            multiValue: (provided) => ({ ...provided, display: "none" }),
            multiValueLabel: (provided) => ({ ...provided, display: "none" }),
            multiValueRemove: (provided) => ({
              ...provided,
              display: "none",
            }),
          }}
          value={selectedTechnologyOptions}
          controlShouldRenderValue={false}
          aria-label="Select Technologies"
        />
        <Select
          isMulti
          name="countries"
          options={countryOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleCountryChange}
          placeholder="Countries"
          styles={{
            ...customStyles,
            multiValue: (provided) => ({ ...provided, display: "none" }),
            multiValueLabel: (provided) => ({ ...provided, display: "none" }),
            multiValueRemove: (provided) => ({
              ...provided,
              display: "none",
            }),
          }}
          value={selectedCountryOptions}
          controlShouldRenderValue={false}
          aria-label="Select Countries"
        />
        <Select
          isMulti
          name="categories"
          options={categoryOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={handleCategoryChange}
          placeholder="Categories"
          styles={{
            ...customStyles,
            multiValue: (provided) => ({ ...provided, display: "none" }),
            multiValueLabel: (provided) => ({ ...provided, display: "none" }),
            multiValueRemove: (provided) => ({
              ...provided,
              display: "none",
            }),
          }}
          value={selectedCategoryOptions}
          controlShouldRenderValue={false}
          aria-label="Select Categories"
        />
        <Select
          name="postedOn"
          options={postedOnOptions}
          className="basic-select"
          classNamePrefix="select"
          onChange={handlePostedOnChange}
          placeholder="Posted On"
          styles={customStyles}
          value={postedOn}
          isClearable
          aria-label="Select Posted On"
        />
        <Select
          name="salaryRange"
          options={salaryOptions}
          className="basic-select"
          classNamePrefix="select"
          onChange={handleSalaryRangeChange}
          placeholder="Monthly Salary"
          styles={customStyles}
          value={selectedSalaryRange}
          isClearable
          aria-label="Monthly Salary"
        />

        <Link
          to="/insights"
          className={`button gradient-input rounded-full`}
          aria-label="Go to Insights for Vacancies"
        >
          <span style={{ width: "220px!important" }}>Vacancies Insights</span>
        </Link>
      </form>
    </>
  );
};

export default FiltersForm;
